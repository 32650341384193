<template>
  <molecules-wrapper>
    <molecules-custom-dialog
      v-if="showRemove"
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Untuk konfirmasi, masukkan email dari user yang akan dihapus (${
        $store.state.user.data &&
        $store.state.user.data.find((x) => x._id === showRemove) &&
        $store.state.user.data.find((x) => x._id === showRemove).email
      })`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeUser()
          },
        },
      ]"
    >
      <atoms-text-field
        color="background"
        label="Tulis kembali email user..."
        :noLabel="true"
        v-model="removeValidator"
    /></molecules-custom-dialog>
    <v-row>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.user.total} Users`"
          :desc="`Sampai Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple-plus"
          color="green"
          :title="`${($store.state.user.currentDay && $store.state.user.currentDay.total) || 0} Terdaftar Hari Ini`"
          :desc="`${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-clock"
          color="orange"
          :title="`${
            ($store.state.user.within7Days &&
              $store.state.user.within7Days.map((x) => x.total).reduce((x, y) => x + y, 0)) ||
            0
          } Users Terdaftar`"
          :desc="`Dalam 7 Hari Terakhir`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <v-row>
        <v-col cols="12" md="8">
          <atoms-text-field class="rounded-lg accent" noLabel>
            <v-text-field
              v-model.trim="term"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              @click:append="
                () => {
                  term = ''
                }
              "
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-close"
              class="rounded-lg ma-0 pa-0"
              hide-details
              flat
              solo
              dense
            >
              <template v-slot:prepend>
                <v-combobox
                  class="rounded-lg ma-0 pa-0"
                  placeholder="Query"
                  v-model="searchFilter"
                  :items="searchFilters.map((x) => x.title)"
                  hide-details
                  multiple
                  dense
                  solo
                  flat
                  required
                >
                  <template v-slot:selection="{ item, index }">
                    <span style="white-space: nowrap" class="pa-0"
                      >{{ (index == 0 && item) || (index == 1 && '..') || '' }}
                    </span>
                  </template></v-combobox
                >
              </template></v-text-field
            ></atoms-text-field
          >
        </v-col>
        <v-col cols="6" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Users</atoms-button
          >
        </v-col>
        <v-col cols="6" md="2">
          <atoms-button @click="showAdd = true" style="width: 100%" class="primary body--text"
            ><v-icon left>mdi-plus</v-icon>Tambah User</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getUsers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Dibuat: {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            type="submit"
            style="width: 100%"
            class="teal lighten-5 teal--text text--darken-2"
            @click.prevent="exportXlsx"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
            <span v-if="loadingXlsxPercentage">{{ loadingXlsxPercentage }}%</span></atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto" class="ml-auto">
          <v-select
            v-model="filter.role"
            :items="listRoles"
            item-text="label"
            item-value="value"
            placeholder="Filter Role"
            :loading="loadingListRoles"
            class="rounded-lg"
            :menu-props="{ offsetY: true }"
            dense
            solo
            flat
            @keyup.delete="filter.role = undefined"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-select
            v-model="filter.applyReferralGroup"
            :items="workerReferrals"
            item-text="title"
            item-value="_id"
            placeholder="Filter Referral"
            :loading="referralLoading"
            class="rounded-lg"
            :menu-props="{ offsetY: true }"
            dense
            solo
            flat
            @keyup.delete="filter.applyReferralGroup = undefined"
          />
        </v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.user.data && $store.state.user.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ picture, nickname, ...rest }, i) in $store.state.user.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <v-avatar size="50" color="grey lighten-4 rounded-pill">
                  <atoms-image :enableZoom="true" v-if="picture" :src="picture" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize((nickname && nickname) || 'Member')
                }}</atoms-text>
                <atoms-text class="primary--text">{{
                  (rest && rest.email) || 'pengguna.email@website.com'
                }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >User sejak {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <atoms-text class="primary--text"
                  >Akun diperbarui {{ $moment(rest && rest._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <!-- <atoms-text class="gray--text"
                  >( referral : {{ rest.applyReferralGroup && rest.applyReferralGroup.title }} )</atoms-text
                > -->
              </v-col>
              <v-col align="center">
                <v-chip small class="rounded-lg" :color="(rest.isEmailVerified && 'primary') || 'red white--text'">{{
                  rest.isEmailVerified ? 'Verified' : 'Not Yet Verified'
                }}</v-chip>
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item
                      link
                      :v-if="
                        !$store.state.user.roles.includes($roles.Super_Admin) || $store.state.user._id === rest._id
                      "
                      @click="showRemove = ($store.state.user._id !== rest._id && rest._id) || null"
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-delete-sweep</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :disabled="!$store.state.user.roles.includes($roles.Super_Admin)"
                      @click="
                        showEdit = {
                          picture,
                          nickname,
                          ...rest,
                        }
                      "
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-account-edit</v-icon>
                      <v-list-item-title class="px-2">Edit {{ nickname && nickname.split(' ')[0] }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        $store.state.user.roles.includes($roles.Admin) ||
                        $store.state.user.roles.includes($roles.Super_Admin)
                      "
                      link
                      @click="resetPassword({ picture, nickname, ...rest })"
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-key-alert-outline</v-icon>
                      <v-list-item-title class="px-2">Reset Password</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container class="px-0 pb-0">
              <v-chip small class="rounded-lg mr-1" v-for="(x, i) in rest.roles" :key="i">
                {{ x }}
              </v-chip>
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  class="my-4"
                  v-model="page"
                  @change="loadData"
                  :length="$store.state.user.pagesLength"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex flex-column justify-center align-center my-8 py-8 outlined">
          <div><atoms-image :width="250" src="/assets/missing.svg" /></div>
          <atoms-title :h3="true" class="mt-6">User yang anda cari tidak ditemukan!</atoms-title>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-users-register
        v-if="showAdd"
        @closed="
          () => {
            showAdd = false
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-users-edit
        v-if="showEdit"
        :data="showEdit"
        @closed="
          () => {
            showEdit = false
          }
        "
      />
    </v-slide-x-reverse-transition>
  </molecules-wrapper>
</template>
<script>
export default {
  data() {
    return {
      term: '',
      sort: '',
      showAdd: false,
      showRemove: null,
      showEdit: null,
      workerReferrals: null,
      removeValidator: '',

      filter: {
        role: '',
      },
      searchFilter: null,
      searchFilters: [
        {
          title: 'Nama Pengguna',
          value: (term) => ({
            nickname: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },

        {
          title: 'Email',
          value: (term) => ({
            email: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },

        {
          title: 'Email Verified',
          value: () => ({
            isEmailVerified: true,
          }),
        },
      ],
      listRoles: [],
      loadingListRoles: false,
      constant: ['Desc', 'Asc'],
      mainLoading: false,
      reportLoading: false,
      limit: 10,
      page: 1,
      loadingXlsxPercentage: 0,
    }
  },
  watch: {
    page: {
      handler() {
        this.getSummary()
        this.getUsers()
      },
      deep: true,
    },
    'filter.role': {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
    'filter.applyReferralGroup': {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
  },
  mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.fetchListRoles()
      this.getSummary()
      this.getUsers()
      this.getReferrals()
    },
    async getReferrals() {
      this.referralLoading = true
      try {
        this.workerReferrals = (await this.$api.get('WorkerReferrals/get'))?.result?.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0,
        )
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.referralLoading = false
      }
    },
    async fetchListRoles() {
      this.loadingListRoles = true
      try {
        let roles =
          Object.values(this.$roles).map((role) => ({
            label: role,
            value: role,
          })) || []
        this.listRoles = roles
        this.listRoles = await Promise.all(
          roles.map(async (item) => {
            const query = {}
            if (item?.value !== 'All') {
              query.roles = { $in: item?.value }
            }
            const count = await this.$api
              .get('Users/count', {
                params: {
                  jsonQuery: JSON.stringify(query),
                },
              })
              .then((data) => data?.result || '')
            return {
              label: `${item?.value} (${count} users)`,
              value: item?.value,
            }
          }),
        )
      } catch (error) {
        Promise.reject(error)
      }
      this.loadingListRoles = false
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('user/summary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    getFilter() {
      const query = {}
      if (this.filter.role && this.filter.role !== 'All') {
        query.roles = this.filter.role
      }
      if (this.filter.applyReferralGroup && this.filter.applyReferralGroup !== 'All') {
        query.applyReferralGroup = this.filter.applyReferralGroup
      }
      return {
        ...query,
        $or: [
          ...(this.searchFilter?.length > 0
            ? this.searchFilter?.map((x) => this.searchFilters?.find((y) => y.title === x)?.value?.(this.term))
            : this.searchFilters.map((x) => x?.value?.(this.term))),
        ],
        pipeline: [
          {
            $lookup: {
              from: 'WorkerReferrals',
              localField: 'applyReferralGroup',
              foreignField: '_id',
              as: 'applyReferralGroup',
            },
          },
          {
            $unwind: {
              path: '$applyReferralGroup',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          {
            $project: {
              password: 0,
            },
          },
        ],
      }
    },
    async getUsers() {
      this.mainLoading = true
      try {
        const filter = this.getFilter()
        await this.$store.dispatch('user/getAll', {
          custom: filter,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async removeUser() {
      const email = this.$store.state.user?.data?.find((x) => x?._id === this.showRemove)?.email
      if (this.showRemove && email === this.removeValidator) {
        await this.$store
          .dispatch('user/remove', this.showRemove)
          .then((x) => {
            this.$showDialog({
              title: 'Berhasil',
              body: `${
                this.$store.state.user.data.find((x) => x._id === this.showRemove)?.email || 'User'
              } berhasil dihapus`,
            })
            this.showRemove = null
            this.loadData()
          })
          .catch((x) => {
            this.$showDialog({
              title: 'Gagal',
              body: x,
            })
          })
      } else {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Email yang anda masukkan tidak sesuai dengan email user yang akan dihapus!',
        })
      }
    },
    async resetPassword(user) {
      const isConfirmed = confirm(`Yakin ingin reset password ${user.nickname} jadi 12345678 ?`)
      if (!isConfirmed) return
      await this.$store.commit('SET', { loading: true })
      try {
        const data = await this.$api.post(`Users/resetPasswordByHK`, {
          _id: user?._id,
        })
        if (data?.success) {
          this.$showDialog({
            title: 'Reset Berhasil',
            body: `Password telah direset menjadi 12345678`,
          })
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      await this.$store.commit('SET', { loading: false })
    },
    async exportXlsx() {
      const isConfirmed = confirm('Yakin mau ekspor?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.getFilter()
        const realm = await this.$realm()
        const workers = await realm.functions.getUsers(JSON.stringify(filter))
        const columns = [
          {
            label: 'Nama',
            value: 'Nama',
          },
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'Referral',
            value: 'Referral',
          },
          {
            label: 'Tanggal Dibuat',
            value: 'Tanggal Dibuat',
          },
        ]
        const content = workers.map((item) => {
          return {
            Nama: item.nickname,
            Email: item.email,
            Referral: item?.applyReferralGroup?.title,
            'Tanggal Dibuat': item._createdDate
              ? this.$moment(item._createdDate).utcOffset('+0700').locale('id').format('DD-MM-YYYY HH:mm')
              : undefined,
          }
        })
        let data = [
          {
            sheet: 'Users',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: 'Users',
        }
        this.$xlsx(data, settings)
      } catch (err) {
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
